@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
@import '~antd/dist/antd.less';

body {
  margin: 0;
  padding: 0;
  background-color: gray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@border-radius-base: 10px;@font-family: 'Montserrat', sans-serif;@primary-color: #2bc6ff;@font-size-base: 12px;@text-color: #000000;@tabs-ink-bar-color: @primary-color;@input-placeholder-color: #000000;